module.exports = [{
      plugin: require('/home/runner/work/today-i-learned-content/today-i-learned/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/today-i-learned-content/today-i-learned/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":848,"quality":80,"tracedSVG":true},
    },{
      plugin: require('/home/runner/work/today-i-learned-content/today-i-learned/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/home/runner/work/today-i-learned-content/today-i-learned/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-2908677-12","head":false,"anonymize":true,"respectDNT":true,"exclude":[],"pageTransitionDelay":0,"sampleRate":100,"siteSpeedSampleRate":50,"cookieDomain":"til.cybertec-postgresql.com"},
    },{
      plugin: require('/home/runner/work/today-i-learned-content/today-i-learned/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
