// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/home/runner/work/today-i-learned-content/today-i-learned/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-posts-tsx": () => import("/home/runner/work/today-i-learned-content/today-i-learned/src/templates/posts.tsx" /* webpackChunkName: "component---src-templates-posts-tsx" */),
  "component---src-templates-post-tsx": () => import("/home/runner/work/today-i-learned-content/today-i-learned/src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */),
  "component---src-templates-tag-tsx": () => import("/home/runner/work/today-i-learned-content/today-i-learned/src/templates/tag.tsx" /* webpackChunkName: "component---src-templates-tag-tsx" */),
  "component---src-templates-author-tsx": () => import("/home/runner/work/today-i-learned-content/today-i-learned/src/templates/author.tsx" /* webpackChunkName: "component---src-templates-author-tsx" */),
  "component---src-pages-404-tsx": () => import("/home/runner/work/today-i-learned-content/today-i-learned/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/home/runner/work/today-i-learned-content/today-i-learned/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-imprint-tsx": () => import("/home/runner/work/today-i-learned-content/today-i-learned/src/pages/imprint.tsx" /* webpackChunkName: "component---src-pages-imprint-tsx" */),
  "component---src-pages-privacy-tsx": () => import("/home/runner/work/today-i-learned-content/today-i-learned/src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */)
}

